.navigation-wrapper {
    background: white;
    border-bottom: 1px solid #d2d2da;
    box-shadow: 0 4px 7px 0 rgb(30 29 31 / 10%);
}

.navigation {
    display: block;
}

.navigation ul,
.navigation li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navigation ul {
    display: flex;
    justify-content: space-between;
}

.navigation li {
    width: 100%;
    text-align: center;
    padding: 25px 0;
    border-left: 1px solid #d2d2da;
    cursor: pointer;
    position: relative;
}

.navigation li:last-of-type {
    border-right: 1px solid #d2d2da;
}

.navigation li a {
    text-decoration: none;
    width: 100%;
    height: 100%;
    color: #47535f;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 15px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 50px;
    transition: all 300ms;
}

.navigation li a:hover {    
    -webkit-box-shadow: inset -10px -13px 0px -11px #ffb613;
    -moz-box-shadow: inset -10px -13px 0px -11px #ffb613;
    box-shadow: inset -10px -13px 0px -11px #ffb613;
}

.navigation .second-menu {
    display: none;
    position: absolute;
    z-index: 999;
    background: hotpink;
    max-width: 1200px;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0%); 
}

.navigation .second-menu li.second {
    text-align: left;
    padding-left: 50px;
    width: 100%;
}

.navigation .second-menu li.second:hover a {
    color: red;
    cursor: pointer;
}

.navigation .inomhusskyltar:hover .second-menu {
    display: block;
}

.mobile-menu,
.mobile-close-menu {
    display: none;
}


.mobile-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
}

#threeLines {
    position: relative;
    height: 30px;
    padding: 0px;
    margin: 10px 25px;
    cursor: pointer;
    transition: all 600ms ease;
}

#threeLines #el {
    cursor: pointer;
    width: 30px;
    padding: 0px;
    height: 3px;
    background: #0F3966;
    box-shadow: 0px 0px 0px transparent;
    border: 0px transparent;
    text-indent: -999px;
    position: absolute;
    top: 50%;
    right: 0px;
    margin-top: -1px;
    -webkit-appearance: none;
    line-height: 0px;
    transition: all 300ms ease;
    border-radius: 99px;
}

#threeLines #el:before {
    bottom: -10px;
    left: auto;
    right: 0px;
}

#threeLines #el:after {
    top: -10px;
}

#threeLines #el:before,
#threeLines #el:after {
    display: block;
    content: "";
    position: absolute;
    left: 0px;
    height: 3px;
    background: #0F3966;
    width: 100%;
    transition: all 200ms ease;
    border-radius: 99px;
}

.mobile-menu-visible #threeLines #el {
    background: transparent;
}

.mobile-menu-visible #threeLines #el:before {
    background: white;
    transform: rotate(-45deg) translate(7px, -7px) scale(1.2, 1.2);
}

.mobile-menu-visible #threeLines #el:after {
    background: white;
    transform: rotate(45deg) translate(7px, 7px) scale(1.2, 1.2);
}


@media only screen and (max-width: 820px) {

    .banner .right {
        display: none;
    }

    .navigation-wrapper {
        height: auto;
    }

    .mobile-menu,
    .mobile-close-menu {
        display: block;
    }

    .mobile-close-menu {
        text-align: right;
    }

    .mobile-menu-visible {
        overflow: hidden;
    }

    .navigation {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        background: #0F3966;
        height: 100%;
        width: 100%;
        z-index: 999;
    }

    .navigation-wrapper .inner {
        max-width: 100%;
        padding: 0;
    }

    .navigation ul {
        display: block;
        padding-top: 30px;
    }

    .navigation ul li {
        border: none;
        border-bottom: 1px solid white;
        color: white;
    } 
    
    .navigation ul li a {
        color: white;
        text-transform: none;
        font-size: 20px;
        text-align: left;
        padding-left: 20px;
        border-bottom: 1px solid white;
    } 

    .navigation li a:hover {
        box-shadow: none;
    }

}
